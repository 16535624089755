import { createUseStyles } from "react-jss"

export const useStepperWizardStyles = createUseStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepContent: {
    paddingTop: 0,
  },
  cancelButtonStyle: {
    backgroundColor: theme.color.neutralLight[16],
    color: theme.color.grey[900],
    border: `1px solid ${theme.color.grey[800]}`,
    textTransform: "none",
    height: 40,
    paddingLeft: 27,
    paddingRight: 27,
    width: 106,
    fontSize: 16,
    fontWeight: 600,
  },
  active: {
    color: theme.color.text.primary,
    marginRight: 10,
  },
  inactive: {
    color: theme.color.secondary[600],
    marginRight: 10,
  },
  modalInfo: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  stepperContainer: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: 0,
    backgroundColor: theme.color.background.surfaceElevation1,
    padding: "6px 0px",
    zIndex: theme.zIndex.appBar,
    outline: `2px solid ${theme.color.background.surfaceElevation1}`,
  },
  topDivider: {
    margin: "16px 0px 14px 0px",
  },
}))
