import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import { CUSTOMER_ADMIN_HOSTS } from "src/constants"
import { API_URL, REQUEST_HEADERS } from "src/services"
import { IconNameType } from "src/shared/components/Icons"
import useSWR from "swr"

dayjs.extend(relativeTime)
dayjs.extend(utc)

export type ServiceConnectorsResponseType = {
  id: string
  name: string
  hostname: string | null
  ipAddress: string | null
  services: number
  unpublishServices: number
  siteName: string
  mtu: number
  connectionLastUpdated: string | null
  wgIp: string
  relayWgIp: string
  relayPublicKey: string
  internalIpAddress: string | null
  outerIpAddress?: string
  isNameSet: boolean
  lastStatusUpdate: string
  instanceName: string | null
  agentVersion: string
  siteId: string
  createdAt: string
  upgradeMessage: string
  hostStatusTooltip: string
  network: string
  region: string
  cloudId: string
  vpc: { vpcName?: string; vpcId: string }
  subnet: { subnetName?: string; subnetId?: string }
  hostCreation: keyof typeof mapHostIcon
  hostStatus: HostBeStatus
  connectionStatus: boolean
  instancesCount: number
  serviceNames: string[]
  servicesCount: number
  isHa: boolean
  isLatest: boolean
  softwareVersion: string
  debugMode: boolean
}

export type ServiceConnectorsParsedType = {
  id: string
  name: string
  hostname: string | null
  ipAddress: string | null
  services: number
  unpublishServices: number
  siteName: string
  connectionLastUpdated: string | null
  wgIp: string
  relayWgIp: string
  relayPublicKey: string
  internalIpAddress: string | null
  outerIpAddress?: string
  isNameSet: boolean
  lastStatusUpdate: number
  instanceName: string | null
  agentVersion: string
  siteId: string
  mtu: number
  createdAt: string
  upgradeMessage: string
  warningTooltip: string
  icon: IconNameType | ""
  network: string[]
  region: string[]
  cloudId: string
  vpc?: { key: string; value?: string }[]
  subnet?: { key: string; value?: string }[]
  connectionStatus?: boolean
  hostCreation: keyof typeof mapHostIcon
  hostCreationTooltip: (typeof mapHostCreationTooltip)[keyof typeof mapHostCreationTooltip]
  siteIconTooltip: string
  activeConnectorCount?: number
  inActiveConnectorCount?: number
  instancesCount: number
  serviceNames: string[]
  servicesCount: number
  isHa: boolean
  softwareVersion: string
  hostStatus: string
  hostStatusTooltip: string
  beHostStatus: keyof typeof mapHostStatus
  formattedInstancesCount: string
  formattedServicesCount: string
  metaData?: { key: string; msg: string }
  isDebuggingLogsEnabled?: boolean
}

export type ServiceConnectorsPayloadType = {
  hosts: ServiceConnectorsParsedType[] | null
  total: number
  from?: number
  to?: number
}

type UseHostsResponseType = {
  data?: ServiceConnectorsPayloadType
  isLoading: boolean
  error: any
  isValidating: boolean
  getHosts: () => void
}

type UseConnectorsType = {
  offset?: number
  limit?: number
  query?: string
  shouldFetch?: boolean
  refreshInterval?: number
  filterQuery?: string
}

export enum HostBeStatus {
  NA = "N/A",
  OPERATIONAL = "OPERATIONAL",
  PARTIALLY_OPERATIONAL = "PARTIALLY_OPERATIONAL",
  NON_OPERATIONAL = "NON_OPERATIONAL",
  ENABLE_HA_MODE_IN_PROGRESS = "ENABLE_HA_MODE_IN_PROGRESS",
  DISABLE_HA_MODE_IN_PROGRESS = "DISABLE_HA_MODE_IN_PROGRESS",
  UPDATE_HA_MODE_FAILED = "UPDATE_HA_MODE_FAILED",
  DEPLOYMENT_FAILED = "DEPLOYMENT_FAILED",
  DEPLOYMENT_IN_PROGRESS = "DEPLOYMENT_IN_PROGRESS",
  DELETE_IN_PROGRESS = "DELETE_IN_PROGRESS",
  DELETE_FAILED = "DELETE_FAILED",
}

export enum HostUiStatus {
  NA = "N/A",
  UP = "UP",
  DOWN = "DOWN",
  ENABLING_HA = "Enabling HA...",
  DISABLING_HA = "Disabling HA...",
  DEPLOYING = "Deploying...",
  DELETE_IN_PROGRESS = "Removing...",
}

const mapHostStatus: Record<HostBeStatus, HostUiStatus> = {
  [HostBeStatus.NA]: HostUiStatus.NA,
  [HostBeStatus.OPERATIONAL]: HostUiStatus.UP,
  [HostBeStatus.PARTIALLY_OPERATIONAL]: HostUiStatus.UP,
  [HostBeStatus.NON_OPERATIONAL]: HostUiStatus.DOWN,
  [HostBeStatus.ENABLE_HA_MODE_IN_PROGRESS]: HostUiStatus.ENABLING_HA,
  [HostBeStatus.DISABLE_HA_MODE_IN_PROGRESS]: HostUiStatus.DISABLING_HA,
  [HostBeStatus.UPDATE_HA_MODE_FAILED]: HostUiStatus.UP,
  [HostBeStatus.DEPLOYMENT_FAILED]: HostUiStatus.DOWN,
  [HostBeStatus.DEPLOYMENT_IN_PROGRESS]: HostUiStatus.DEPLOYING,
  [HostBeStatus.DELETE_IN_PROGRESS]: HostUiStatus.DELETE_IN_PROGRESS,
  [HostBeStatus.DELETE_FAILED]: HostUiStatus.DOWN,
} as const

const mapHostStatusTooltip: Record<HostBeStatus, string> = {
  [HostBeStatus.NA]: "No Instances added",
  [HostBeStatus.OPERATIONAL]: "Connector is fully functional and running as expected",
  [HostBeStatus.PARTIALLY_OPERATIONAL]: "Connector is running, but with limited functionality or some issues.",
  [HostBeStatus.NON_OPERATIONAL]: "Connector is not functioning as expected",
  [HostBeStatus.ENABLE_HA_MODE_IN_PROGRESS]: "Enable Connector HA is in progress",
  [HostBeStatus.DISABLE_HA_MODE_IN_PROGRESS]: "Disable Connector HA is in progress",
  [HostBeStatus.UPDATE_HA_MODE_FAILED]: "Failed to update HA mode",
  [HostBeStatus.DEPLOYMENT_FAILED]: "Failed to deploy Service Connector",
  [HostBeStatus.DEPLOYMENT_IN_PROGRESS]: "Deployment of connector underway",
  [HostBeStatus.DELETE_IN_PROGRESS]: "Connector removal in progress",
  [HostBeStatus.DELETE_FAILED]: "Failed to remove connector",
} as const

const mapHostCreationTooltip = {
  AUTO: "Service Connector created automatically",
  MANUAL: "Service Connector created manually",
  AWS: "Service Connector created using Multi-Cloud Integration",
  AZURE: "Service Connector created using Multi-Cloud Integration",
  GCP: "Service Connector created using Multi-Cloud Integration",
} as const

export const mapHostIcon = {
  MANUAL: "buildingPrivate",
  AUTO: "privateHosted",
  AWS: "awsColored",
  AZURE: "azureIntegration",
  GCP: "gcp",
} as const

/**
 * Parses the raw hosts data into a more usable format.
 *
 * @param {HostsResponseType[]} hosts - The raw data of hosts.
 *
 * @returns {HostsParsedType[]} The formatted host data.
 */
export const parseHostsData = (hosts: ServiceConnectorsResponseType[]): ServiceConnectorsParsedType[] =>
  hosts.map((host) => {
    const {
      name,
      hostCreation,
      hostStatus,
      siteName,
      cloudId,
      connectionLastUpdated,
      connectionStatus,
      isNameSet,
      lastStatusUpdate,
      network,
      region,
      subnet,
      vpc,
      mtu,
      instancesCount,
      servicesCount,
      debugMode,
    } = host

    return {
      ...host,
      name: name || "N/A",
      icon: mapHostIcon[hostCreation] || "",
      connectionLastUpdated: connectionLastUpdated ? dayjs(connectionLastUpdated).fromNow() : "N/A",
      beHostStatus: hostStatus,
      hostStatus: mapHostStatus[hostStatus] || "",
      hostStatusTooltip: mapHostStatusTooltip[hostStatus],
      hostCreationTooltip: mapHostCreationTooltip[hostCreation],
      warningTooltip: isNameSet
        ? ""
        : "Please go to “<strong>Edit Service Connector</strong>” and change your service connector alias name once it is successfully added. The Service Connector Alias name should not be similar to Service Connector name.",
      lastStatusUpdate: dayjs().diff(dayjs(lastStatusUpdate)),
      vpc: vpc
        ? [
            { key: "name", value: vpc?.vpcName },
            { key: "id", value: vpc?.vpcId },
          ]
        : undefined,
      subnet: subnet
        ? [
            { key: "name", value: subnet?.subnetName },
            { key: "id", value: subnet?.subnetId },
          ]
        : undefined,
      region: [region],
      network: [network],
      siteIconTooltip: siteName?.length ? "Site Name:  " + siteName : "Site Name:  N/A",
      mtu,
      connectionStatus: !cloudId && instancesCount === 0 ? undefined : connectionStatus,
      formattedInstancesCount: (instancesCount || 0).toString(),
      formattedServicesCount: (servicesCount || 0).toString(),
      isDebuggingLogsEnabled: debugMode,
    }
  })

/**
 * Custom hook to fetch and manage the hosts data.
 *
 * @param {UseConnectorsType} params - Parameters to control the request.
 * @param {number} [params.offset] - The offset for pagination.
 * @param {number} [params.limit] - The limit for pagination.
 * @param {boolean} [params.shouldFetch=true] - Whether to fetch data.
 * @param {number} [params.refreshInterval=10000] - The interval to refresh data.
 * @param {string} [params.filterQuery] - Filter query for the request.
 *
 * @returns {UseHostsResponseType} - The response object contains data, error, isLoading, getHosts, and isValidating.
 */
const useHosts = ({
  offset,
  limit,
  shouldFetch = true,
  refreshInterval = 10000,
  filterQuery,
}: UseConnectorsType): UseHostsResponseType => {
  let url = `${API_URL}${CUSTOMER_ADMIN_HOSTS}?`

  if (limit) url += `&limit=${500}`

  if (offset) url += `&offset=${offset}`

  if (filterQuery) {
    const [param, value] = filterQuery.split("=")
    if (param === "is_ha") {
      url += `&${param}=${value === "ENABLED"}`
    } else {
      url += `&${filterQuery}`
    }
  }

  const { data, error, mutate, isValidating } = useSWR(shouldFetch ? [url, REQUEST_HEADERS] : null, {
    refreshInterval,
  })

  const payload = data?.payload

  return {
    data: payload
      ? {
          hosts: parseHostsData(payload?.hosts),
          from: payload?.from,
          to: payload?.to,
          total: payload?.total,
        }
      : undefined,
    isLoading: !error && !data,
    error,
    getHosts: mutate,
    isValidating,
  }
}

export default useHosts
