import Modal from "src/shared/components/Modal"
import { useAboutUztnaModalStyles } from "./AboutUZTNA.styles"

type AboutUZTNAModalProps = {
  onModalClose: () => void
}

export const AboutUZTNAModal: React.FC<AboutUZTNAModalProps> = ({ onModalClose }): JSX.Element => {
  const classes = useAboutUztnaModalStyles()

  return (
    <Modal
      title={"About Universal ZTNA"}
      onOk={onModalClose}
      onCancel={onModalClose}
      onCancelProps={{ visible: false }}
      onOkProps={{
        title: "Close",
      }}
      width={432}
    >
      <div>
        <div className={classes.content}>
          <div className={classes.boldContent}>Release Version</div>
          <div className={classes.text}>24.2.3.163</div>
        </div>
        <div className={classes.text}>© Copyright 2024 Extreme Networks - All rights reserved.</div>
      </div>
    </Modal>
  )
}
