import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { ENBadge } from "en-react/dist/src/components/Badge"
import { ENChip } from "en-react/dist/src/components/Chip"
import { ENDatetimepickerField } from "en-react/dist/src/components/DatetimepickerField"
import { ENHeading } from "en-react/dist/src/components/Heading"
import { ENProgress } from "en-react/dist/src/components/Progress"
import { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { TROUBLESHOOTRUNTEST } from "src/constants/apiUrlsCraas"
import { authenticationTypeOptions } from "src/constants/dropdownsData"
import { postRequest } from "src/services"
import AppHeaderLayout from "src/shared/components/AppHeaderLayout"
import CircularLoader from "src/shared/components/CicularLoader"
import ErrorContainer from "src/shared/components/ErrorContainer"
import { EnSimpleInput } from "src/shared/components/FormComponents"
import EnSelect from "src/shared/components/FormComponents/EnSelect"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import PageHeader, { PageHeaderType } from "src/shared/components/PageHeader"
import Paper from "src/shared/components/Paper"
import ZtnaButton from "src/shared/components/ZtnaButton"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { resetBreadCrumbs } from "src/store/ui/uiSlice"
import theme from "src/theme"
import { getTransformedMacAddress } from "src/utils"
import { addNetworkPolicyValidation } from "src/utils/validations"
import { useNetworkPolicyEvaluationStyles } from "./NetworkPolicyEvaluation.styles"

type TimeType = {
  date: string | null
  time: string | null
}
interface troubleshootingFormType {
  username: string
  password: string
  mac_address: string
  auth_type: string
  ssid: string
  switch_ip: string
  switch_port: string
  timestamp: TimeType
  newTimestamp: string
}
interface TroubleshootingData {
  AuthStatus: string
  AuthFailureReason: string
  RuleFailureReason: string
  DeviceCompliance: string
  DeviceComplianceReason: string
  MacAuth: boolean
  MatchedPolicy: string
  ResponseAttr: string[]
  UserAuth: string
  ProcessedRuleDescriptions: [
    {
      Policy: string
      Status: string
      MatchResults: [{ Status: string; Description: string }, { Status: string; Description: string }]
    },
  ]
}

const NetworkPolicyEvaluation: React.FC = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const classes = useNetworkPolicyEvaluationStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const queries = new URLSearchParams(location.search)
  const macAddress = queries.get("macAddress") || ""
  const userName = queries.get("userName") || ""
  const ssId = queries.get("ssId") || ""
  const nasIp = queries.get("nasIp") || ""
  const authType = queries.get("authType") || ""
  const switchPort = queries.get("switchPort") || ""
  const [evaluationData, setEvaluationData] = useState<TroubleshootingData | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [showCount, setShowCount] = useState(2)

  const divRef = useRef<HTMLDivElement>(null)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, dirtyFields, touchedFields },
  } = useForm<troubleshootingFormType>({
    defaultValues: {
      mac_address: "",
      username: "",
      password: "",
      ssid: "",
      auth_type: "",
      switch_ip: "",
      switch_port: "",
      timestamp: {
        date: null,
        time: null,
      },
      newTimestamp: "",
    },
    mode: "onChange",
    resolver: yupResolver(addNetworkPolicyValidation),
  })

  const { mac_address, ssid, username, password, auth_type, switch_ip, switch_port, newTimestamp } = watch()
  const [isCopied, setIsCopied] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    dispatch(resetBreadCrumbs())
  }, [])

  const handleCopyClick = async () => {
    try {
      const jsonString = JSON.stringify(evaluationData, null, 2)
      const stringWithoutBraces = jsonString.replace(/[{}]/g, "")
      await navigator.clipboard.writeText(stringWithoutBraces)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 1000)
    } catch (error) {}
  }
  const downloadTextFile = () => {
    const jsonString = JSON.stringify(evaluationData, null, 2)
    const textContent = jsonString.replace(/[{}]/g, "")
    const blob = new Blob([textContent], { type: "text/plain" })
    const blobUrl = URL.createObjectURL(blob)
    const anchor = document.createElement("a")
    anchor.href = blobUrl
    anchor.download = "Evaluation_Result.txt"
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    URL.revokeObjectURL(blobUrl)
  }
  const expandResult = () => {
    setShowAll(!showAll)
  }
  useEffect(() => {
    if (!showAll) {
      setShowCount(2)
    } else {
      setShowCount(evaluationData?.ProcessedRuleDescriptions?.length || 2)
    }
  }, [showAll])

  useEffect(() => {
    let portValue = switchPort?.toString()
    reset({
      mac_address: macAddress === "undefined" ? "" : macAddress,
      switch_port: portValue === "0" ? "" : portValue,
      auth_type: authType,
      username: userName === "undefined" ? "" : userName,
      ssid: ssId === "undefined" ? "" : ssId,
      switch_ip: nasIp === "undefined" ? "" : nasIp,
    })
    navigate(location.pathname)
  }, [reset, navigate, location.pathname])

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const runTest = async () => {
    setError("")
    if (isValid) {
      setEvaluationData(null)
      setIsSubmitting(true)
      const apiBody = {
        username: username,
        password: password,
        mac_address: getTransformedMacAddress(mac_address),
        auth_type: auth_type,
        ssid: ssid,
        switch_ip: switch_ip,
        switch_port: switch_port?.toString(),
        timestamp: convertDateTime(newTimestamp), //calTimestamp(timestamp?.date, timestamp?.time),
      }
      try {
        await delay(1000)
        let { data } = await postRequest(TROUBLESHOOTRUNTEST, apiBody)
        setEvaluationData(data)
        setIsSubmitting(false)
      } catch (error: any) {
        setIsSubmitting(false)
        setError(error?.message)
      }
    }
  }
  const clearData = () => {
    reset()
    reset({
      mac_address: "",
      switch_port: "",
      auth_type: "",
      username: "",
      ssid: "",
      switch_ip: "",
      newTimestamp: "",
    })
    setEvaluationData(null)
  }

  /* const calTimestamp = (dateISO: string | null, timeISO: string | null) => {
    if (dateISO || timeISO) {
      const parsedDate = dateISO ? dayjs(dateISO) : dayjs(dayjs().format())
      const parsedTime = timeISO ? dayjs(timeISO) : dayjs(dayjs().format())
      const combinedDateTime = parsedDate.set("hour", parsedTime.hour()).set("minute", parsedTime.minute())
      const result = combinedDateTime.toISOString()
      return result
    } else return null
  } */

  const convertDateTime = (newTimestamp: string) => {
    if (newTimestamp !== "") {
      const parts = newTimestamp.split("(")
      const inputDate = parts[0].trim()
      const inputTime = parts[1] ? parts[1].replace(")", "").trim() : getCurrentTime()
      const [month, day, year] = inputDate.split("/")
      const [hours, minutes] = inputTime.split(":")
      const dateTime = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00Z`)
      const formattedDateTime = dateTime.toISOString()

      return formattedDateTime
    } else return null
  }
  const getCurrentTime = () => {
    const now = new Date()
    const hours = now.getHours()
    const minutes = now.getMinutes()
    const formattedHours = hours < 10 ? "0" + hours : hours
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes
    const currentTime = `${formattedHours}:${formattedMinutes}`

    return currentTime
  }
  const displayResponseAttrTitle = (event: any) => {
    if (event) {
      const indexValuePairs = evaluationData?.ResponseAttr.map((value, index) => `${value}`).join("\n")
      event.target.title = indexValuePairs
    }
  }

  const pageHeaderProps: PageHeaderType = {
    title: "Policy Evaluation & Troubleshooting",
    marginBottom: 17.5,
  }

  return (
    <div className={classes.container}>
      <AppHeaderLayout
        slots={{
          pageHeader: <PageHeader noPortal title={pageHeaderProps.title} />,
        }}
      />
      <ErrorContainer
        message={error}
        open={!!error}
        handleClose={() => setError("")}
        errorClass={classes.errorContainer}
      />
      <div className={classes.paper}>
        <form className={classes.marginBottom35}>
          <>
            <div className={classes.hostingProviderContainer}>
              <div className={classes.simpleInput}>
                <EnSimpleInput
                  label="MAC Address"
                  control={control}
                  placeholder="MAC Address"
                  name="mac_address"
                  error={errors.mac_address}
                  dataTestId="input-mac_address"
                />
              </div>

              <div>
                <div className={classes.select}>
                  <Controller
                    name="auth_type"
                    control={control}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <EnSelect
                        label="Authentication Type"
                        options={authenticationTypeOptions}
                        optionValue="value"
                        optionLabel="label"
                        value={value}
                        onChange={onChange}
                        error={touchedFields.auth_type ? errors.auth_type : ""}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className={classes.hostingProviderContainer}>
              <div className={classes.simpleInput}>
                <EnSimpleInput
                  label="User Name"
                  control={control}
                  placeholder="User Name"
                  name="username"
                  type="email"
                  isOptional
                  dataTestId="input-username"
                />
              </div>
              <div className={classes.simpleInput}>
                <EnSimpleInput
                  label="Password"
                  control={control}
                  placeholder="Password"
                  name="password"
                  isPasswordField
                  isOptional
                  dataTestId="input-password"
                />
              </div>
            </div>

            <div className={classes.hostingProviderContainer}>
              <div className={classes.simpleInput}>
                <EnSimpleInput
                  label="SSID"
                  control={control}
                  placeholder="SSID"
                  name="ssid"
                  isOptional
                  dataTestId="input-SSID"
                />
              </div>
              <div className={classes.simpleInput}>
                <EnSimpleInput
                  label="AP/Switch IP"
                  control={control}
                  placeholder="AP/Switch IP"
                  name="switch_ip"
                  error={errors.switch_ip}
                  isOptional
                  dataTestId="input-switch_io"
                />
              </div>
            </div>
            <div className={classes.hostingProviderContainer}>
              <div className={classes.simpleInput}>
                <EnSimpleInput
                  label="Switch Port"
                  control={control}
                  placeholder="Switch Port"
                  name="switch_port"
                  error={errors.switch_port}
                  isOptional
                  dataTestId="input-switch_port"
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="newTimestamp"
                  render={({ field: { onChange, value } }) => (
                    <ENDatetimepickerField
                      placeholder="Select Date & Time"
                      label="Select Date & Time"
                      value={value}
                      onDateChanged={(val) => {
                        onChange(val)
                      }}
                      isOptional={true}
                      enableDynamicPosition={false}
                      verticalAlign="bottom"
                      dateFormat="MM/dd/yyyy"
                      hideSeconds={true}
                    />
                  )}
                />
              </div>
            </div>
          </>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              position: "relative",
              bottom: 0,
              right: 0,
              gap: "10px",
              left: "21.5rem",
            }}
          >
            <ZtnaButton
              buttonType="secondary"
              dataTestid="show-all-button"
              disabled={!dirtyFields}
              onClick={() => {
                clearData()
              }}
              title="Clear"
              style={{ width: 100, marginRight: 15 }}
            />
            <ZtnaButton
              buttonType="primary"
              dataTestid="show-all-button"
              onClick={() => {
                handleSubmit(runTest)()
              }}
              title={evaluationData === null ? "Evaluate" : "Re-evaluate"}
              disabled={!isValid}
              style={{ width: 100 }}
              startIcon={isSubmitting && <CircularLoader isDark />}
            />
          </div>
          <div>
            <ENProgress currentProgress={0} endProgress={isSubmitting ? 1 : 0} duration={4} />
          </div>
          {/* <LoadingDialog visible={isSubmitting} text="Please wait while we gather the necessary information." /> */}
        </form>
      </div>

      {evaluationData && (
        <div ref={divRef}>
          <div
            className={clsx(
              classes.dFlex,
              classes.alignCenter,
              classes.mb12,
              classes.justifyBetween,
              classes.marginmtmdop,
            )}
          >
            <div className={clsx(classes.dFlex, classes.alignCenter)}>
              <ENHeading variant="lg">Evaluation Result</ENHeading>
              <div
                className={clsx(classes.infoIconContainer, classes.dFlex)}
                onClick={() => {
                  handleCopyClick()
                }}
              >
                <ZtnaTooltip title="Copied" placement="top" arrow open={isCopied}>
                  <span className={classes.iconInfo}>
                    <ZtnaIcon name="roundEdgeCopy" color={theme.color.grey[300]} />
                  </span>
                </ZtnaTooltip>
                <span
                  className={classes.iconInfo}
                  onClick={() => {
                    downloadTextFile()
                  }}
                >
                  <ZtnaIcon name="download2" color={theme.color.grey[300]} />
                </span>
              </div>
            </div>
          </div>

          <div className={clsx(classes.grid2, classes.dgrid)}>
            <div>
              <ENHeading variant="md">Authentication Test</ENHeading>
              <div className={clsx(classes.result, classes.dgrid, classes.gridTemplateColumns4_6)}>
                <div>
                  <ENHeading>Authentication Status</ENHeading>
                </div>
                <div>
                  <ENChip variant="secondary">
                    <ENBadge
                      isDot={true}
                      variant={
                        evaluationData?.MacAuth
                          ? evaluationData?.AuthStatus === "rejected"
                            ? "danger"
                            : evaluationData?.AuthStatus === "accepted"
                            ? "success"
                            : "neutral"
                          : evaluationData?.UserAuth === "FAILED"
                          ? "danger"
                          : evaluationData?.UserAuth === "SUCCESS"
                          ? "success"
                          : "neutral"
                      }
                    ></ENBadge>
                    {evaluationData?.MacAuth
                      ? evaluationData?.AuthStatus === "rejected"
                        ? "Failed"
                        : evaluationData?.AuthStatus === "accepted"
                        ? "Success"
                        : "Skipped"
                      : evaluationData?.UserAuth === "FAILED"
                      ? "Failed"
                      : evaluationData?.UserAuth === "SUCCESS"
                      ? "Success"
                      : "Skipped"}
                  </ENChip>
                </div>
              </div>
              <div>
                {evaluationData?.UserAuth === "SKIPPED" && auth_type === "EAP-TLS" ? null : evaluationData?.UserAuth ===
                    "FAILED" || evaluationData?.UserAuth === "SKIPPED" ? (
                  evaluationData?.RuleFailureReason || evaluationData?.AuthFailureReason ? (
                    <div className={clsx(classes.result, classes.dgrid, classes.gridTemplateColumns4_6)}>
                      <div>
                        <ENHeading>Authentication Failure Reason</ENHeading>
                      </div>
                      <div
                        className={clsx(classes.label, classes.textTrim)}
                        title={
                          evaluationData?.UserAuth === "SKIPPED"
                            ? evaluationData?.RuleFailureReason
                            : evaluationData?.AuthFailureReason
                        }
                      >
                        {evaluationData?.UserAuth === "SKIPPED"
                          ? evaluationData?.RuleFailureReason
                          : evaluationData?.AuthFailureReason}
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>

            <div>
              <ENHeading variant="md">Compliance Test</ENHeading>
              <div className={clsx(classes.result, classes.dgrid, classes.gridTemplateColumns4_6)}>
                <div>
                  <ENHeading>Compliance Status</ENHeading>
                </div>
                <div>
                  <ENChip variant="secondary">
                    <ENBadge
                      isDot={true}
                      variant={
                        evaluationData?.DeviceCompliance === "SUCCESS"
                          ? "success"
                          : evaluationData?.DeviceCompliance === "SKIPPED"
                          ? "neutral"
                          : "danger"
                      }
                    ></ENBadge>
                    {evaluationData?.DeviceCompliance === "SUCCESS"
                      ? "Compliant"
                      : evaluationData?.DeviceCompliance === "SKIPPED"
                      ? "N/A"
                      : "Non-Compliant"}
                  </ENChip>
                </div>
              </div>
              <div>
                {evaluationData?.DeviceCompliance !== "SUCCESS" && (
                  <div className={clsx(classes.result, classes.dgrid, classes.gridTemplateColumns4_6)}>
                    <div>
                      <ENHeading>Non Compliance Reason</ENHeading>
                    </div>
                    <div
                      className={clsx(classes.label, classes.textTrim)}
                      title={evaluationData?.DeviceComplianceReason}
                    >
                      {evaluationData?.DeviceComplianceReason}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={clsx(classes.mb12, classes.marginmtmdop)}>
            <ENHeading variant="md">Policy Test</ENHeading>
          </div>
          <div className={classes.returnAttr}>
            <ENHeading variant="sm">Matched Policy : </ENHeading>
            <div className={classes.para}>
              {evaluationData?.MatchedPolicy === "" ? "N/A" : evaluationData?.MatchedPolicy}
            </div>
          </div>

          <div className={classes.returnAttr}>
            <ENHeading variant="sm">Return Attributes : </ENHeading>
            {evaluationData?.ResponseAttr[0] === "" ? (
              <div className={classes.para}>{"N/A"}</div>
            ) : (
              <div className={classes.para} onMouseEnter={displayResponseAttrTitle}>
                {evaluationData?.ResponseAttr.join(", ")}
              </div>
            )}
          </div>

          <Paper>
            <div className={classes.scrollbar}>
              {evaluationData?.ProcessedRuleDescriptions.slice(0, showCount).map((object, index) => (
                <div className={classes.repeatblk}>
                  <div className={classes.content}>
                    <ZtnaIcon
                      name={object.Status === "fail" ? "dangerOct" : "circleGreenTick"}
                      height="15"
                      width="15"
                    />
                    <ENHeading className={classes.marginrightsm} variant="sm">
                      {object.Policy}
                    </ENHeading>
                  </div>
                  <div className={classes.contentBlk}>
                    {object.MatchResults.map((result) => (
                      <div className={classes.testDesc}>
                        <span
                          className={
                            result.Status === "fail"
                              ? clsx(classes.circle, classes.red)
                              : clsx(classes.circle, classes.green)
                          }
                        ></span>
                        {result.Description}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {evaluationData?.ProcessedRuleDescriptions.length > 1 ? (
              <ZtnaButton
                buttonType="tertiary"
                dataTestid="show-all-button"
                className={classes.show}
                onClick={() => {
                  expandResult()
                }}
                title={showAll ? `Show Less` : `Show More`}
                style={{ width: 100, marginRight: 35 }}
              />
            ) : null}
          </Paper>
        </div>
      )}
    </div>
  )
}

export default NetworkPolicyEvaluation
